<template>
  <div>
    <v-container>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ผู้ใช้งานระบบ-ข้าราชการครูและบุคลการทางการศึกษา"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row no-gutters>     
            <v-col cols="12" class="pa-1">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                    single-line
                    hide-details
                    v-on:keyup.enter="OnEnter()"
                    filled
                    class="mb-2"
                  />
                  <v-radio-group row>
                    <v-radio
                      label="ชื่อ"
                      value="radio-1"
                      @click.native="search_name()"
                    ></v-radio>
                    <v-radio
                      label="นามสกุล"
                      value="radio-2"
                      @click.native="search_lastname()"
                    ></v-radio>
                    <v-radio
                      label="รหัสบัตรประชาชน"
                      value="radio-3"
                      @click.native="search_idcard()"
                    ></v-radio>

                    <v-radio
                      label="ปีที่เกษียณ"
                      value="radio-5"
                      @click.native="search_yearretire()"
                    ></v-radio>
                    <v-radio
                      label="แสดงทั้งหมด"
                      value="radio-6"
                      @click.native="personnel_temporaryQueryAll()"
                    ></v-radio>
                  </v-radio-group>
                </v-col>           
             
                <v-col cols="4" class="pa-1">
                  <v-autocomplete
                    v-model="college_search"
                    outlined
                    :items="colleges"
                    item-text="college_name"
                    item-value="college_code"
                    label="สถานศึกษา"
                    @change="search_college()"
                  >
                  </v-autocomplete>
                </v-col>

          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="personnel_temporary_admins"
          :search="search"
        >
          <template v-slot:item.user_status="{ item }">
            <span v-if="item.user_status == 'teacher'">ครู</span>
            <span v-if="item.user_status == 'se_director'">รองผู้อำนวยการ</span>
            <span v-if="item.user_status == 'director'">ผู้อำนวยการ</span>
            <span v-if="item.user_status == 'supervision'">ศึกษานิเทศก์</span>
            <span v-if="item.user_status == '38'">สนับสนุน</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="personnel_temporary_adminEdit(item.id_card)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model editpersonnel_temporary_admindialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editpersonnel_temporary_admindialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลผู้ใช้งานระบบ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editpersonnel_temporary_adminform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-alert
                        icon="mdi-shield-lock-outline"
                        prominent
                        text
                        type="info"
                      >
                        {{
                          editpersonnel_temporary_admin.title_s +
                            editpersonnel_temporary_admin.frist_name +
                            " " +
                            editpersonnel_temporary_admin.last_name
                        }}
                        {{ editpersonnel_temporary_admin.id_card }}
                      </v-alert>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="Password"
                        v-model="editpersonnel_temporary_admin.p_word"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"                      
                        :type="show1 ? 'text' : 'password'"
                        hint="At least 8 characters"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="Confirm Password"
                        v-model="
                          editpersonnel_temporary_admin.personnel_temporary_admin_confirmpassword
                        "
                         :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"                      
                        :type="show2 ? 'text' : 'password'"
                        hint="At least 8 characters"
                        counter
                        @click:append="show2 = !show2"
                         :rules="[v => v == editpersonnel_temporary_admin.p_word]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editpersonnel_temporary_admindialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editpersonnel_temporary_adminSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      show1: false,
      show2: false,
      valid: true,
      editpersonnel_temporary_admindialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      personnel_temporary_admins: [],
      editpersonnel_temporary_admin: {},
      search: "",
      pagination: {},
      headers: [     
        { text: "รหัสสถานศึกษา", align: "center", value: "college_code" },
        { text: "ID Card", align: "center", value: "id_card" },
        { text: "ชื่อ", align: "center", value: "frist_name" },
        { text: "นามสกุล", align: "center", value: "last_name" },
        { text: "สถานะ", align: "center", value: "user_status" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      personnel_temporary_adminstatus: [],
      userstatus: {},
      data_syslog: {},
      idcard:"",
      college_search:{},
      colleges:[],
      position_search:{},
      position_select:{}
    };
  },
  async mounted() {
   let result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });

    this.colleges = result.data;
  },
  methods: {

    async sweetAlertLoading() {
          Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();         
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },


    async search_name() {
      await this.sweetAlertLoading();
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        frist_name: this.search
      });
      this.personnel_temporary_admins = result.data;
      Swal.close();
    },

    async search_lastname() {
      await this.sweetAlertLoading();
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        last_name: this.search
      });
      this.personnel_temporary_admins = result.data;
      Swal.close();
    },

    async search_idcard() {
      await this.sweetAlertLoading();
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_cards: this.search
      });
      this.personnel_temporary_admins = result.data;
      Swal.close();
    },

    async search_college() {
      await this.sweetAlertLoading();
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          college_code: this.college_search
        })
        .finally(() => (this.loading = false));
      this.personnel_temporary_admins = result.data;
      Swal.close();
    },

    async search_position() {
      await this.sweetAlertLoading();
      await this.search_positionQuery();
      Swal.close();
    },
    async search_positionQuery() {
      await this.sweetAlertLoading();
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          user_status: this.position_search
        })
        .finally(() => (this.loading = false));
      this.personnel_temporary_admins = result.data;
      Swal.close();
    },

    async search_rang() {
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          rang_name: this.rangname_search
        })
        .finally(() => (this.loading = false));
      this.personnel_temporary_admins = result.data;
    },
    async search_yearretire() {
      await this.sweetAlertLoading();
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          retrire_year: this.search
        })
        .finally(() => (this.loading = false));
      this.personnel_temporary_admins = result.data;
      Swal.close();
    },



    async personnel_temporary_adminQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary_admin.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.personnel_temporary_admins = result.data;
      
    },

    async personnel_temporary_adminEdit(id_card) {
      let result = await this.$http.post("personnel_temporary_admin.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpersonnel_temporary_admin = result.data;
      this.editpersonnel_temporary_admin.p_word = "";
      this.editpersonnel_temporary_admindialog = true;
      
    },
    async editpersonnel_temporary_adminSubmit() {
      if (this.$refs.editpersonnel_temporary_adminform.validate()) {
        this.editpersonnel_temporary_admin.ApiKey = this.ApiKey;
        if (this.editpersonnel_temporary_admin.p_word == "")
          delete this.editpersonnel_temporary_admin.p_word;
        let result = await this.$http.post(
          "personnel_temporary.update.password.php",
          this.editpersonnel_temporary_admin
        );
        if (result.data.status == true) {
          this.personnel_temporary_admin = result.data;
          Swal.fire({
            icon: 'success',
            title: 'ดำเนินการแก้ไขข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          this.personnel_temporary_adminQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "personnel";
          this.data_syslog.table_log = "personnel_temporary";
          this.data_syslog.detail_log = this.editpersonnel_temporary_admin.id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'ดำเนินการผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
        }
        this.editpersonnel_temporary_admindialog = false;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
